import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";
import { CaseStudyCtaGoTo } from "./generative-ai-itsm";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_Image = require("../../../assets/img/blogs/hr_blog_cover_img.png");
const section_1 = require("../../../assets/img/blogs/gasic_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/gasic_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/gasic_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/gasic_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/gasic_blog_img_5.png");

const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Cut ITSM Costs with Conversational AI & GenAI"
        description="Optimize IT service management costs with a conversational AI platform."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={topImage}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt gasic_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog color-white">
                    Top 5 Ways CXOs Can Save ITSM Cost Using Generative AI
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog color-white">
                    Top 5 Ways CXOs Can <br />
                    Save ITSM Cost Using
                    <br /> Generative AI
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Reduction of TCO with Generative AI
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Fewer overhead costs for chatbot maintenance and training
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Minimizing consumption of prompt tokens
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Drop in reliance on third-party support services
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Retention of top talent through SLA compliance
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section6"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section6"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section6")}
                >
                  6. Unlocking cost efficiency with Workativ Hybrid NLU
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section7"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section7"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section7")}
                >
                  7. Conclusion
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Advances in natural language processes through the manifestation
                of Generative AI or its underlying technologies, deep-neural
                network-based large language models have massive economic
                potential on a global scale.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Riding the new wave of Generative AI innovation, businesses and
                society can leverage massive productivity and economic gains by
                automating routine tasks, streamlining workflows, and creating
                new business applications.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                According to Goldman Sachs Research, this incredible Generative
                AI potential could{" "}
                <a href="https://www.goldmansachs.com/intelligence/pages/generative-ai-could-raise-global-gdp-by-7-percent.html">
                  help businesses drive a 7% increase in global GDP or create 7
                  trillion worth of economic value over a 10-year period.
                </a>{" "}
                This similar report also suggests productivity growth by 1.5
                points.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Generative AI drives cost efficiency for enterprise leaders in ITSM "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Perhaps this is relieving news for CFOs in every industry
                looking to reduce the balance sheet burden during the toughest
                economic times when Eurozone and the US have already slipped
                into recession.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The higher inflation costs have them rethinking their IT
                allocation budget strategies 一 putting automation on top of
                their priority list to optimize operational efficiency and save
                costs. Generative AI promises to recession-proof your business
                and drive toward cost efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In fact, the seamless human-machine interactivity with
                accessibility to LLM capability also provides significant
                <a href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023">
                  business benefits for ITSM operations.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Alike many CFOs or CXO, if you also aim to ramp up cost
                efficiency and improve ITSM operational efficiency, here’s our
                article. Keep reading to reveal how Generative AI helps you save
                <a href="https://workativ.com/conversational-ai-platform/blog/enterprise-service-desk-guide">
                  {" "}
                  service desk management
                </a>{" "}
                costs.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Reduction of TCO with Generative AI
              </h2>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Generative AI helps reduce TCO for ITSM"
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Password resets lead to a growing employee challenge, coupled
                with other mundane IT support tasks. According to Forrester, 66%
                of employees prefer solving their password reset problems using
                a self-service mechanism.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="user preference is password reset automation "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Unfortunately, over a third of an organization’s service desk
                cannot resolve this problem effectively.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Even with self-service support, most organizations use chatbots
                with less advanced AI technology and limited capacity. When
                queries go beyond pre-defined FAQs or conversation templates,
                self-service isn’t able to draw users’ intent and provide
                relevant information for their queries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                What happens, then? The service request moves through several
                tiers of support. With that increases the cumulative price of a
                ticket, which also combines salaries of agents, salaries of
                third-party service, office supplies, rental of facilities, and
                a wide range of IT tools.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 gen_ai_blog_para">
                For example, if a ticket costs up to $10 at self-service or
                0-tier level, it can cost up to $47 when it reaches Level 1 [the
                cost of 0-tier ($10) and the cost of tier-1 ($37)]. The higher
                the tier-level support, the higher the ticket cost due to an
                investment of time, effort, expertise, and IT resources.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By improving the intelligence of self-service, Generative AI
                efficiently delivers user experience by resolving many common
                service requests and fostering a shift-left strategy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Generative AI being attached to LLM or the entire internet,
                it can fetch enormous datasets or massively diverse knowledge
                sets to provide a unified self-service interface.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The unique capabilities of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise-pros-cons">
                  Generative AI on the service management side help
                  organizations -
                </a>
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Allow their employees to find precise information directly,
                  not via links or other elaborate documents
                </li>
                <li className="font-section-normal-text-testimonials">
                  Offer information with accurate citations to improve validity
                </li>
                <li className="font-section-normal-text-testimonials">
                  Create summaries and titles with speed to reduce the time for
                  incident response
                </li>
                <li className="font-section-normal-text-testimonials">
                  Offer remediation plans
                </li>
                <li className="font-section-normal-text-testimonials">
                  Reduce self-service training time
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                As these unique capabilities offer a fast and accurate response,
                Generative AI prevents reliance on the next-level- tier support
                and nurtures a shift-left strategy to effectively manage
                downstream activities so that service requests get resolved at
                the lower-cost delivery channels.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, Generative AI aims to resolve issues at the
                zero-level tier and reduces the total cost of ownership, with an
                increase in agent productivity that can be dedicated to
                high-level tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A study also supports that{" "}
                <a href="https://www.nber.org/papers/w31161?ref=txt.cohere.com&{query}">
                  Generative AI-based conversational assistant improves user
                  productivity to perform 14% more user requests in an hour.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Some more cost savings for you!
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Fewer overhead costs for chatbot maintenance and training
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                When building a chatbot, it is obvious to continuously train the
                language model to conform to the present business scenario or
                use cases so as to allow users to find information to perform a
                task or solve a problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the ability to self-learn from inputs of user interaction,
                the Gen AI chatbot can keep ingesting data and make changes to
                its behavior.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, in an enterprise setting, a Generative AI
                chatbot needs to have ample data for training to simplify
                domain-specific use cases. In scenarios where data is scarce, a
                large language model can be used to{" "}
                <a href="https://direct.mit.edu/tacl/article/doi/10.1162/tacl_a_00492/112605/Generate-Annotate-and-Learn-NLP-with-Synthetic">
                  generate synthetic data and train the models to support
                  self-service functionality.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By 2025, Generative AI techniques would augment creative work by
                30% — and one of the many industry use cases would be{" "}
                <a href="https://www.gartner.com/en/articles/beyond-chatgpt-the-future-of-generative-ai-for-enterprises">
                  synthetic data generation,
                </a>{" "}
                claims Brian Burke, Research VP for Technology Innovation at
                Gartner.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The benefit of using synthetic data is it saves costs on subject
                matter experts for data generation or buying data services from
                a third-party service provider to a great extent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The added bonus is when data is limited, a large language model
                helps generate NLP training data and pushes for fast time to
                market for chatbot implementation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a whole, the need for fine-tuning will go down, improving
                cost efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Minimizing consumption of prompt tokens
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Optimizing large language models provides maximum logical
                reasoning capabilities that improve self-service for enterprise
                use cases. But each prompt also brings high costs for token
                consumption during prompt inputs and query generation. For
                example, OpenAI’s DaVinci model uses 1,000 tokens per query,
                which costs about $0.12.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If the number of prompt tokens can be reduced, the cost of using
                large language models for each conversation will also decrease.
                You can use prompt engineering to modify the prompt while
                requesting a query and take control of the token budget.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, you want to know about ‘how to apply for sick
                leaves’? You can specify the number of tokens or paragraphs to
                retrieve your answer for this specific HR request.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Ask a Generative AI interface to surface the information within
                100 tokens or 3 sentences, it will certainly do it while
                maintaining the truthfulness of the information.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                data-name=" Controlling token consumption by specifying prompt tokens while asking a query 
                "
                alt=" Reduction of prompt tokens for cost reduction in ITSM communications"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Stanford researchers also claimed that{" "}
                <a href="https://arxiv.org/abs/2305.05176">
                  reducing the number of prompts helps reduce the computational
                  costs of using large language models while improving the
                  output quality.
                </a>{" "}
                They further claimed even with the reduction of 100 tokens, one
                could expect huge savings on prompt tokens when used multiple
                times.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Drop in reliance on third-party support services
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Backed by large language models, a Generative AI self-service is
                more efficient and effective in resolving end-user queries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Interestingly, the human-machine interaction feels more human
                and useful in cutting down additional steps to look for human
                assistance.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the flexibility of generating knowledge articles relevant
                to the thousands of IT or HR support use cases available online
                or particular enterprise domain-specific, the Generative AI can
                power conversational AI and surface any kind of information to
                help your people solve common and repetitive tasks immediately.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if a user has IT issues with his desktop, which
                shuts down frequently when running an application like ERP, a
                more specific user guide or knowledge article can instantly help
                the user fix the issue and get back to work.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This, in return, reduces the reliance on third-party support
                resources, regardless of the fact that your organization will
                grow and scale because Generative AI utilizes the power of
                self-learning and improves the ability to detect NLP queries and
                their intent.
              </p>
              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Retention of top talent through SLA compliance
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                In the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">
                  ITSM spectrum,
                </a>{" "}
                complying with user experience by delivering the right IT
                support is significant. The instances of change management must
                be properly communicated to the internal employees so they can
                be well informed of the situation and prepared to take their
                work ahead.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Using extraordinary capabilities to automate incident detection
                through root cause analysis by synthesizing its large language
                model, Generative AI can predict upcoming incidents to the IT
                assets, thereby alerting the right person to take charge and
                reduce the impact of the incidents on the user productivity and
                experience.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 ">
                Similarly, there are fewer wait times for most common workplace
                support issues when your conversational AI self-service uses
                Generative AI properties.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                For example, an onboarding process takes up much HR time and
                effort. Although it is the same old repetitive process, HR
                professionals must invest a lot of time in assisting a new hire
                with documentation processes, accessing company policies, or
                being invited for an introduction session with a company
                stakeholder.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                Users can surface specific information and get going by using
                simple prompts in the{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational AI interface.
                </a>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                The instantaneous and accurate information delivery capability
                that improves auto-resolution effectively helps boost user
                satisfaction levels and make them feel valued.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                The possible business outcome of Generative AI user experience
                includes reduced DSAT, increased CSAT, and long-term employee
                engagement. As a result, businesses are less likely to restart
                their hiring process and scale with their top talent without the
                fear of attrition.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Unlocking cost efficiency with Workativ Hybrid NLU
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ is a dedicated player in the conversational AI segment
                whose objective is to untangle the complexities of employee
                support in the modern workplace.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Using its conversational AI platform that leverages the large
                language model properties, Workativ improves 80% of repetitive
                tasks with app workflow automation.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Above the cut, the Workativ Hybrid NLU, which improves user
                response by surfacing the most relevant response for user
                queries, Workativ ensures users get maximum benefits on the
                Hybrid chat interface no matter how complicated the NLP query
                turns out, considering domain-specific knowledge around
                enterprise needs.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="search result improvement with Hybrid NLU in the ITSM context

                "
              />

              <p class="font-section-normal-text-testimonials line-height-18">
                With Workativ Hybrid NLU, it is easier for your employees to
                boost self-service for common tasks such as
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  User provisioning and access authorization
                </li>
                <li className="font-section-normal-text-testimonials">
                  Password resets
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employee training and development
                </li>
                <li className="font-section-normal-text-testimonials">
                  Software install or upgrade
                </li>
                <li className="font-section-normal-text-testimonials">
                  Agent handoffs
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                All mundane tasks can be seamlessly automated using{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  Workativ conversational AI chatbot{" "}
                </a>{" "}
                for MS Team, Slack, or Web Widget at ease.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                On top of it, the conversation on Workativ Virtual Agent feels
                more like a human, improving user experience and reducing
                reliance on human agents. As a result, it gradually reduces the
                human effort from L1 to L4, eliminating the high ticket handling
                costs and helping your organization conform to the shift-left
                strategy and reduce TCO.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                For example, when using automation in an organization with an
                employee headcount of about 3000, the organization tends to
                reduce costs of service from $45,000 monthly to $21,000 per
                month. Although the cost does not include IT tools, capex, HR
                costs, and ITSM licensing fees, automation has a staggering
                amount of benefits for self-service functionality.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                When using Generative AI, your ability to maximize self-service
                increases for employee support, which gives cost savings through
                avoided internal HR services, real-time resolutions of common IT
                issues, and, finally, cost savings for an increase in employee
                productivity.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                The bottom line is Generative AI, combined with conversational
                AI, enables CFOs to optimize working capital efficiency and
                drive financial gains for ITSM to drive business success
                further.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                In addition to cost savings, McKinsey estimates that Generative
                AI can automate{" "}
                <a href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/mckinsey%20digital/our%20insights/the%20economic%20potential%20of%20generative%20ai%20the%20next%20productivity%20frontier/the-economic-potential-of-generative-ai-the-next-productivity-frontier-vf.pdf">
                  60 to 70 percent of the work time of a worker
                </a>{" "}
                to complete a job due to its ability to understand natural
                language.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Furthermore, Generative AI can augment labor productivity from
                0.1 to 0.6 percent through 2040. The implication of Generation
                AI use cases would lie in maximizing them to their fullest
                potential and driving business results.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Want to drive cost optimization for your ITSM?{" "}
                <a href="/conversational-ai-platform/demo">
                  Schedule an appointment with Workativ Sales experts to learn
                  more.
                </a>
              </p>
            </div>

            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Reduction of TCO with Generative AI
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Fewer overhead costs for chatbot maintenance and training
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Minimizing consumption of prompt tokens
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Drop in reliance on third-party support services
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Retention of top talent through SLA compliance
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section6"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section6"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section6")}
                  >
                    6. Unlocking cost efficiency with Workativ Hybrid NLU
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section7"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section7"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section7")}
                  >
                    7. Conclusion
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Advances in natural language processes through the manifestation
                of Generative AI or its underlying technologies, deep-neural
                network-based large language models have massive economic
                potential on a global scale.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Riding the new wave of Generative AI innovation, businesses and
                society can leverage massive productivity and economic gains by
                automating routine tasks, streamlining workflows, and creating
                new business applications.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                According to Goldman Sachs Research, this incredible Generative
                AI potential could{" "}
                <a href="https://www.goldmansachs.com/intelligence/pages/generative-ai-could-raise-global-gdp-by-7-percent.html">
                  help businesses drive a 7% increase in global GDP or create 7
                  trillion worth of economic value over a 10-year period.
                </a>{" "}
                This similar report also suggests productivity growth by 1.5
                points.
              </p>
              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="Generative AI drives cost efficiency for enterprise leaders in ITSM "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Perhaps this is relieving news for CFOs in every industry
                looking to reduce the balance sheet burden during the toughest
                economic times when Eurozone and the US have already slipped
                into recession.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The higher inflation costs have them rethinking their IT
                allocation budget strategies 一 putting automation on top of
                their priority list to optimize operational efficiency and save
                costs. Generative AI promises to recession-proof your business
                and drive toward cost efficiency.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In fact, the seamless human-machine interactivity with
                accessibility to LLM capability also provides significant
                <a href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023">
                  business benefits for ITSM operations.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Alike many CFOs or CXO, if you also aim to ramp up cost
                efficiency and improve ITSM operational efficiency, here’s our
                article. Keep reading to reveal how Generative AI helps you save
                <a href="https://workativ.com/conversational-ai-platform/blog/enterprise-service-desk-guide">
                  {" "}
                  service desk management
                </a>{" "}
                costs.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Reduction of TCO with Generative AI
              </h2>
              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Generative AI helps reduce TCO for ITSM"
              />
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Password resets lead to a growing employee challenge, coupled
                with other mundane IT support tasks. According to Forrester, 66%
                of employees prefer solving their password reset problems using
                a self-service mechanism.
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="user preference is password reset automation "
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Unfortunately, over a third of an organization’s service desk
                cannot resolve this problem effectively.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Even with self-service support, most organizations use chatbots
                with less advanced AI technology and limited capacity. When
                queries go beyond pre-defined FAQs or conversation templates,
                self-service isn’t able to draw users’ intent and provide
                relevant information for their queries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                What happens, then? The service request moves through several
                tiers of support. With that increases the cumulative price of a
                ticket, which also combines salaries of agents, salaries of
                third-party service, office supplies, rental of facilities, and
                a wide range of IT tools.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 gen_ai_blog_para">
                For example, if a ticket costs up to $10 at self-service or
                0-tier level, it can cost up to $47 when it reaches Level 1 [the
                cost of 0-tier ($10) and the cost of tier-1 ($37)]. The higher
                the tier-level support, the higher the ticket cost due to an
                investment of time, effort, expertise, and IT resources.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By improving the intelligence of self-service, Generative AI
                efficiently delivers user experience by resolving many common
                service requests and fostering a shift-left strategy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With Generative AI being attached to LLM or the entire internet,
                it can fetch enormous datasets or massively diverse knowledge
                sets to provide a unified self-service interface.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The unique capabilities of{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-enterprise-pros-cons">
                  Generative AI on the service management side help
                  organizations -
                </a>
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  Allow their employees to find precise information directly,
                  not via links or other elaborate documents
                </li>
                <li className="font-section-normal-text-testimonials">
                  Offer information with accurate citations to improve validity
                </li>
                <li className="font-section-normal-text-testimonials">
                  Create summaries and titles with speed to reduce the time for
                  incident response
                </li>
                <li className="font-section-normal-text-testimonials">
                  Offer remediation plans
                </li>
                <li className="font-section-normal-text-testimonials">
                  Reduce self-service training time
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                As these unique capabilities offer a fast and accurate response,
                Generative AI prevents reliance on the next-level- tier support
                and nurtures a shift-left strategy to effectively manage
                downstream activities so that service requests get resolved at
                the lower-cost delivery channels.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, Generative AI aims to resolve issues at the
                zero-level tier and reduces the total cost of ownership, with an
                increase in agent productivity that can be dedicated to
                high-level tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A study also supports that{" "}
                <a href="https://www.nber.org/papers/w31161?ref=txt.cohere.com&{query}">
                  Generative AI-based conversational assistant improves user
                  productivity to perform 14% more user requests in an hour.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Some more cost savings for you!
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Fewer overhead costs for chatbot maintenance and training
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                When building a chatbot, it is obvious to continuously train the
                language model to conform to the present business scenario or
                use cases so as to allow users to find information to perform a
                task or solve a problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With the ability to self-learn from inputs of user interaction,
                the Gen AI chatbot can keep ingesting data and make changes to
                its behavior.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                On the other hand, in an enterprise setting, a Generative AI
                chatbot needs to have ample data for training to simplify
                domain-specific use cases. In scenarios where data is scarce, a
                large language model can be used to{" "}
                <a href="https://direct.mit.edu/tacl/article/doi/10.1162/tacl_a_00492/112605/Generate-Annotate-and-Learn-NLP-with-Synthetic">
                  generate synthetic data and train the models to support
                  self-service functionality.
                </a>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By 2025, Generative AI techniques would augment creative work by
                30% — and one of the many industry use cases would be{" "}
                <a href="https://www.gartner.com/en/articles/beyond-chatgpt-the-future-of-generative-ai-for-enterprises">
                  synthetic data generation,
                </a>{" "}
                claims Brian Burke, Research VP for Technology Innovation at
                Gartner.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The benefit of using synthetic data is it saves costs on subject
                matter experts for data generation or buying data services from
                a third-party service provider to a great extent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The added bonus is when data is limited, a large language model
                helps generate NLP training data and pushes for fast time to
                market for chatbot implementation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a whole, the need for fine-tuning will go down, improving
                cost efficiency.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Minimizing consumption of prompt tokens
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Optimizing large language models provides maximum logical
                reasoning capabilities that improve self-service for enterprise
                use cases. But each prompt also brings high costs for token
                consumption during prompt inputs and query generation. For
                example, OpenAI’s DaVinci model uses 1,000 tokens per query,
                which costs about $0.12.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If the number of prompt tokens can be reduced, the cost of using
                large language models for each conversation will also decrease.
                You can use prompt engineering to modify the prompt while
                requesting a query and take control of the token budget.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, you want to know about ‘how to apply for sick
                leaves’? You can specify the number of tokens or paragraphs to
                retrieve your answer for this specific HR request.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                Ask a Generative AI interface to surface the information within
                100 tokens or 3 sentences, it will certainly do it while
                maintaining the truthfulness of the information.
              </p>
              <img
                src={section_4}
                className="blog_image_top_bt"
                data-name=" Controlling token consumption by specifying prompt tokens while asking a query 
                "
                alt=" Reduction of prompt tokens for cost reduction in ITSM communications"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Stanford researchers also claimed that{" "}
                <a href="https://arxiv.org/abs/2305.05176">
                  reducing the number of prompts helps reduce the computational
                  costs of using large language models while improving the
                  output quality.
                </a>{" "}
                They further claimed even with the reduction of 100 tokens, one
                could expect huge savings on prompt tokens when used multiple
                times.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Drop in reliance on third-party support services
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Backed by large language models, a Generative AI self-service is
                more efficient and effective in resolving end-user queries.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Interestingly, the human-machine interaction feels more human
                and useful in cutting down additional steps to look for human
                assistance.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                With the flexibility of generating knowledge articles relevant
                to the thousands of IT or HR support use cases available online
                or particular enterprise domain-specific, the Generative AI can
                power conversational AI and surface any kind of information to
                help your people solve common and repetitive tasks immediately.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                For example, if a user has IT issues with his desktop, which
                shuts down frequently when running an application like ERP, a
                more specific user guide or knowledge article can instantly help
                the user fix the issue and get back to work.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 mb-0 pb-0">
                This, in return, reduces the reliance on third-party support
                resources, regardless of the fact that your organization will
                grow and scale because Generative AI utilizes the power of
                self-learning and improves the ability to detect NLP queries and
                their intent.
              </p>
              <CaseStudyCtaGoTo colorKey="peach" textColor="blue" />
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Retention of top talent through SLA compliance
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                In the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/itsm-chatbot-guide">
                  ITSM spectrum,
                </a>{" "}
                complying with user experience by delivering the right IT
                support is significant. The instances of change management must
                be properly communicated to the internal employees so they can
                be well informed of the situation and prepared to take their
                work ahead.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                Using extraordinary capabilities to automate incident detection
                through root cause analysis by synthesizing its large language
                model, Generative AI can predict upcoming incidents to the IT
                assets, thereby alerting the right person to take charge and
                reduce the impact of the incidents on the user productivity and
                experience.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 ">
                Similarly, there are fewer wait times for most common workplace
                support issues when your conversational AI self-service uses
                Generative AI properties.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                For example, an onboarding process takes up much HR time and
                effort. Although it is the same old repetitive process, HR
                professionals must invest a lot of time in assisting a new hire
                with documentation processes, accessing company policies, or
                being invited for an introduction session with a company
                stakeholder.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 ">
                Users can surface specific information and get going by using
                simple prompts in the{" "}
                <a href="https://workativ.com/conversational-ai-platform">
                  conversational AI interface.
                </a>
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                The instantaneous and accurate information delivery capability
                that improves auto-resolution effectively helps boost user
                satisfaction levels and make them feel valued.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                The possible business outcome of Generative AI user experience
                includes reduced DSAT, increased CSAT, and long-term employee
                engagement. As a result, businesses are less likely to restart
                their hiring process and scale with their top talent without the
                fear of attrition.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section6">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Unlocking cost efficiency with Workativ Hybrid NLU
              </h2>
              <p class="font-section-normal-text-testimonials line-height-18">
                Workativ is a dedicated player in the conversational AI segment
                whose objective is to untangle the complexities of employee
                support in the modern workplace.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Using its conversational AI platform that leverages the large
                language model properties, Workativ improves 80% of repetitive
                tasks with app workflow automation.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                Above the cut, the Workativ Hybrid NLU, which improves user
                response by surfacing the most relevant response for user
                queries, Workativ ensures users get maximum benefits on the
                Hybrid chat interface no matter how complicated the NLP query
                turns out, considering domain-specific knowledge around
                enterprise needs.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="search result improvement with Hybrid NLU in the ITSM context

                "
              />

              <p class="font-section-normal-text-testimonials line-height-18">
                With Workativ Hybrid NLU, it is easier for your employees to
                boost self-service for common tasks such as
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials">
                  User provisioning and access authorization
                </li>
                <li className="font-section-normal-text-testimonials">
                  Password resets
                </li>
                <li className="font-section-normal-text-testimonials">
                  Employee training and development
                </li>
                <li className="font-section-normal-text-testimonials">
                  Software install or upgrade
                </li>
                <li className="font-section-normal-text-testimonials">
                  Agent handoffs
                </li>
              </ul>
              <p class="font-section-normal-text-testimonials line-height-18">
                All mundane tasks can be seamlessly automated using{" "}
                <a href="https://workativ.com/conversational-ai-platform/conversational-ai">
                  Workativ conversational AI chatbot{" "}
                </a>{" "}
                for MS Team, Slack, or Web Widget at ease.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                On top of it, the conversation on Workativ Virtual Agent feels
                more like a human, improving user experience and reducing
                reliance on human agents. As a result, it gradually reduces the
                human effort from L1 to L4, eliminating the high ticket handling
                costs and helping your organization conform to the shift-left
                strategy and reduce TCO.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18 mb-0">
                For example, when using automation in an organization with an
                employee headcount of about 3000, the organization tends to
                reduce costs of service from $45,000 monthly to $21,000 per
                month. Although the cost does not include IT tools, capex, HR
                costs, and ITSM licensing fees, automation has a staggering
                amount of benefits for self-service functionality.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section7">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p class="font-section-normal-text-testimonials line-height-18">
                When using Generative AI, your ability to maximize self-service
                increases for employee support, which gives cost savings through
                avoided internal HR services, real-time resolutions of common IT
                issues, and, finally, cost savings for an increase in employee
                productivity.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                The bottom line is Generative AI, combined with conversational
                AI, enables CFOs to optimize working capital efficiency and
                drive financial gains for ITSM to drive business success
                further.
              </p>

              <p class="font-section-normal-text-testimonials line-height-18">
                In addition to cost savings, McKinsey estimates that Generative
                AI can automate{" "}
                <a href="https://www.mckinsey.com/~/media/mckinsey/business%20functions/mckinsey%20digital/our%20insights/the%20economic%20potential%20of%20generative%20ai%20the%20next%20productivity%20frontier/the-economic-potential-of-generative-ai-the-next-productivity-frontier-vf.pdf">
                  60 to 70 percent of the work time of a worker
                </a>{" "}
                to complete a job due to its ability to understand natural
                language.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Furthermore, Generative AI can augment labor productivity from
                0.1 to 0.6 percent through 2040. The implication of Generation
                AI use cases would lie in maximizing them to their fullest
                potential and driving business results.
              </p>
              <p class="font-section-normal-text-testimonials line-height-18">
                Want to drive cost optimization for your ITSM?{" "}
                <a href="/conversational-ai-platform/demo">
                  Schedule an appointment with Workativ Sales experts to learn
                  more.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-service-management-guide"
              className="font-section-normal-text-testimonials"
            >
              A Complete Guide - 2023: HR Service Management
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023"
              className="font-section-normal-text-testimonials"
            >
              The ultimate guide - 2023: HR DIGITAL TRANSFORMATION
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/itsm-digital-transformation"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Why do you need ITSM in your Digital Trasformation?
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
